<template>
  <section class="new-offer mt-5">
    <div class="container">
      <page-title class="mb-5">
        发起交易
      </page-title>

      <div class="trade-card">
        <div class="trade-card__header">
          <span class="text-white">
            向
            <span class="text-primary">{{ receiverInfo.name }}</span>
            发起交易
          </span>
          <!-- <span class="text-danger">{{ data.status }}</span> -->
        </div>
        <div class="trade-card__body">
          <div class="i-row px-3">
            <div class="col">
              <div class="i-row mb-4 d-flex" style="flex-wrap:nowrap;height:48px">
                <b-avatar rounded :src="user.info.avatar_url" />
                <div class="px-3">
                  <div class="text-white">我 ({{ user.info.name }}) 的库存物品</div>
                </div>
              </div>

              <!-- <tabs v-model="myTab" :options="types" /> -->
              <div class="d-flex">
                <drop-down-multiple-select v-model="myTab" :data="types" />
                <search-input v-model="myItemsKeywords" class="ml-auto" width="200px" placeholder="搜索关键词" />
              </div>

              <div v-if="myItemsLoading" class="i-row p-4 mt-2 items-container">
                <div v-for="i in 8" :key="i" class="offer-item--new p-2">
                  <b-skeleton width="100%" height="100px" class="m-0" style="border-radius: 0" />
                </div>
              </div>

              <div v-else class="i-row p-2 mt-2 items-container">
                <div v-if="!filteredMyItems || filteredMyItems.length === 0" class="no-item">
                  <fa :icon="['fa', 'info-circle']" /> 库存中暂无可交易饰品
                </div>
                <offer-item
                  v-for="(i, k) in filteredMyItems"
                  :key="k"
                  class="offer-item--new"
                  :inventory="i"
                  :is-selected="isSelected(i, 'my')"
                  @select="select(i, 'my')"
                  @remove="remove(i, 'my')"
                />
              </div>
            </div>

            <div class="col">
              <div class="i-row mb-4 d-flex" style="flex-wrap:nowrap;height:48px">
                <b-avatar rounded :src="receiverInfo.avatar_url" />
                <div class="px-3">
                  <div class="text-white">
                    <span v-if="isToMerchant" class="text-danger">平台</span>
                    <span v-else>对方</span>
                    ({{ receiverInfo.name }}) 的库存物品
                  </div>
                </div>
              </div>

              <!-- <tabs v-model="receiverTab" :options="types" /> -->
              <div class="d-flex">
                <drop-down-multiple-select v-model="receiverTab" :data="types" />
                <search-input v-model="receiverItemsKeywords" class="ml-auto" width="200px" placeholder="搜索关键词" />
              </div>

              <div v-if="receiverItemsLoading" class="i-row p-4 mt-2 items-container">
                <div v-for="i in 8" :key="i" class="offer-item--new p-2">
                  <b-skeleton width="100%" height="100px" class="m-0" style="border-radius: 0" />
                </div>
              </div>

              <div v-else class="i-row p-2 mt-2 items-container">
                <div v-if="!filteredReceiverItems || filteredReceiverItems.length === 0" class="no-item">
                  <fa :icon="['fa', 'info-circle']" /> 库存中暂无可交易饰品
                </div>
                <offer-item
                  v-for="(i, k) in filteredReceiverItems"
                  :key="k"
                  class="offer-item--new"
                  :inventory="i"
                  :is-selected="isSelected(i, 'his')"
                  @select="select(i, 'his')"
                  @remove="remove(i, 'his')"
                />
              </div>
            </div>
          </div>

          <div class="i-row px-3 mt-4">
            <div class="col">
              <div class="text-secondary">
                以下是您在本次交易中
                <span class="text-primary">失去</span>
                的饰品
              </div>

              <div class="i-row py-4">
                <offer-item
                  v-for="(i, k) in selectedMyItems"
                  :key="k"
                  class="offer-item--new"
                  :inventory="i"
                  remove-only
                  @remove="remove(i, 'my')"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-secondary">
                以下是您在本次交易中
                <span class="text-primary">得到</span>
                的饰品
              </div>

              <div class="i-row py-4">
                <offer-item
                  v-for="(i, k) in selectedReceiverItems"
                  :key="k"
                  class="offer-item--new"
                  :inventory="i"
                  remove-only
                  @remove="remove(i, 'his')"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="trade-card__footer align-items-center">
          <div class="text-secondary" style="flex-shrink:0">
            添加备注：
          </div>
          <form-input v-model="message" class="ml-auto" width="100%" />
        </div>
      </div>

      <div class="d-flex" style="width:100%">
        <btn class="btn-outline-secondary ml-auto mr-3 px-4" :disabled="createLoading" @click="$router.push('/offer')">
          取消
        </btn>
        <div v-if="isSendingToMe" v-b-tooltip.hover.top title="无法给自己发起报价">
          <btn class="btn-primary px-4" disabled @click="createOffer">
            发起报价
          </btn>
        </div>
        <btn v-else class="btn-primary px-4" :loading="createLoading" :disabled="notReady" @click="createOffer">
          发起报价
        </btn>
      </div>
    </div>
  </section>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import { OfferService, UserService } from '../../services/api'
import { mapGetters } from 'vuex'
import OfferItem from './OfferItem.vue'
import { errorParser } from '@/utils'

const TYPES = [
  { label: 'CS:GO', value: 730 },
  { label: 'Dota', value: 570 },
]

export default {
  name: 'NewOffer',
  components: { PageTitle, OfferItem },
  data() {
    return {
      receiverItems: [],
      myItems: [],
      receiverItemsLoading: false,
      myItemsLoading: false,
      selectedReceiverItems: [],
      selectedMyItems: [],
      receiverInfo: {},
      createLoading: false,
      myTab: [TYPES[0]],
      receiverTab: [TYPES[0]],
      types: TYPES,
      myItemsKeywords: '',
      receiverItemsKeywords: '',
      message: '',
    }
  },
  computed: {
    isToMerchant() {
      return this.$router.currentRoute.name === 'NewOfferToMerchant'
    },
    ...mapGetters(['user']),
    filteredReceiverItems() {
      return this.receiverItems.filter(i => {
        const kw = this.receiverItemsKeywords.toLowerCase()
        const a = i.item.name.toLowerCase().includes(kw) || i.item.market_hash_name.toLowerCase().includes(kw)
        let b = false
        this.receiverTab.forEach(j => {
          if (j.value === i.item.app_id) b = true
        })
        return a && b
      })
    },
    filteredMyItems() {
      return this.myItems.filter(i => {
        const kw = this.myItemsKeywords.toLowerCase()
        const a = i.item.name.toLowerCase().includes(kw) || i.item.market_hash_name.toLowerCase().includes(kw)
        let b = false
        this.myTab.forEach(j => {
          if (j.value === i.item.app_id) b = true
        })
        return a && b
      })
    },
    notReady() {
      return (
        !this.selectedReceiverItems ||
        !this.selectedMyItems ||
        (this.selectedReceiverItems.length === 0 && this.selectedMyItems.length === 0)
      )
    },
    isSendingToMe() {
      return this.user.info.id === this.receiverInfo.id
    },
  },
  mounted() {
    const tradeUrl = this.$router.currentRoute.params.tradeToken

    if (this.isSendingToMe) {
      this.toast('无法向自己发起报价')
    }
    this.getMyInventories()
    if (this.isToMerchant) {
      this.receiverInfo = {
        name: 'YesSkins',
        id: 'yesskins',
        avatar_url:
          'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/b3/b38aaaa7a17320d162c575044e0702a302789c21_full.jpg',
        updated_at: '',
        trade_url: 'yesskins',
      }
      this.getMerchantInfo()
    } else {
      this.getUserInventories(tradeUrl)
    }
  },
  methods: {
    toast(message) {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        variant: 'warning',
        solid: true,
      })
    },
    selectQueryItems(ids) {
      this.myItems.forEach(i => {
        ids.forEach(id => {
          if (id.toString() === i.id.toString()) {
            this.selectedMyItems.push(i)
          }
        })
      })
    },
    getMerchantInfo() {
      const key = this.$router.currentRoute.params.tradeToken
      OfferService.getMerchantById(key).then(({ data }) => {
        this.receiverInfo = data.user_base_info
      })
    },
    async getUserInventories(tradeUrl) {
      this.receiverItemsLoading = true
      try {
        const { data } = await UserService.getUserInventories(tradeUrl)
        this.receiverItems = data.inventories.filter(i => i.status === 10000)
        this.receiverInfo = data.user_base_info
      } catch (e) {
        console.log(e)
        this.toast(errorParser(e.response).message)
      } finally {
        this.receiverItemsLoading = false
      }
    },
    async getMyInventories() {
      this.myItemsLoading = true
      try {
        const { data } = await UserService.getInventories()
        this.myItems = data.filter(i => i.status === 10000)

        if (this.$route.query.i_id) {
          this.selectQueryItems(this.$route.query.i_id)
        }
      } catch (e) {
        this.toast(errorParser(e.response).message)
      } finally {
        this.myItemsLoading = false
      }
    },
    isSelected(item, flag) {
      if (flag === 'my') {
        if (this.selectedMyItems.indexOf(item) > -1) return true
      }
      if (flag === 'his') {
        if (this.selectedReceiverItems.indexOf(item) > -1) return true
      }
      return false
    },
    select(item, flag) {
      if (flag === 'my') {
        this.selectedMyItems.push(item)
      }
      if (flag === 'his') {
        this.selectedReceiverItems.push(item)
      }
    },
    remove(item, flag) {
      if (flag === 'my') {
        const idx = this.selectedMyItems.indexOf(item)
        this.selectedMyItems.splice(idx, 1)
      }
      if (flag === 'his') {
        const idx = this.selectedReceiverItems.indexOf(item)
        this.selectedReceiverItems.splice(idx, 1)
      }
    },
    createOffer() {
      this.createLoading = true
      const trade_url = this.$router.currentRoute.params.tradeToken
      const sender_inventory_ids = this.selectedMyItems.map(i => i.id)

      if (this.isToMerchant) {
        const payload = {
          // yes: 1, gift: 2
          merchant_key: trade_url,
          inventory_ids: sender_inventory_ids,
        }
        OfferService.createOfferToMerchant(payload)
          .then(() => {
            this.$router.push('/offer')
          })
          .catch(e => {
            this.toast(errorParser(e.response).message)
          })
          .finally(() => {
            this.createLoading = false
          })
      } else {
        const receiver_inventory_ids = this.selectedReceiverItems.map(i => i.id)
        const payload = {
          trade_url,
          sender_inventory_ids,
          receiver_inventory_ids,
          message: this.message,
        }
        OfferService.createOffer(payload)
          .then(() => {
            this.$router.push('/offer')
          })
          .catch(e => {
            this.toast(errorParser(e.response).message)
          })
          .finally(() => {
            this.createLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/style.scss';

.no-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $secondary;
}

.items-container {
  background-color: #0b0e18;
  width: 100%;
  height: 408px;
  overflow: auto;
  align-content: flex-start;
}

.offer-item--new {
  flex: 0.25 0.25 100%;
  max-width: 25%;
  height: 135px;
}

.trade-card {
  width: 100%;
  margin-bottom: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 30px;
    background: $bg-opacity;
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 60px,
      calc(100% - 5px) calc(100% - 19.37px),
      calc(100% - 10px) calc(100% - 17.32px),
      calc(100% - 15px) calc(100% - 13.23px),
      calc(100% - 16px) calc(100% - 12px),
      calc(100% - 17px) calc(100% - 10.54px),
      calc(100% - 18px) calc(100% - 8.72px),
      calc(100% - 19px) calc(100% - 6.25px),
      calc(100% - 19.5px) calc(100% - 4.44px),
      calc(100% - 20px) 100%,
      20px 100%,
      19.5px calc(100% - 4.44px),
      19px calc(100% - 6.25px),
      18px calc(100% - 8.72px),
      17px calc(100% - 10.54px),
      15px calc(100% - 13.23px),
      10px calc(100% - 17.32px),
      5px calc(100% - 19.37px),
      0 60px
    );
    border-bottom: 1px dashed #2d3139;
  }
  &__body {
    width: 100%;
    padding: 20px;
    background: $bg-opacity;
    border-top: 1px dashed #2d3139;
    border-bottom: 1px dashed #2d3139;
    clip-path: polygon(
      0% 20px,
      5px 19.37px,
      10px 17.32px,
      15px 13.23px,
      16px 12px,
      17px 10.54px,
      18px 8.72px,
      19px 6.25px,
      19.5px 4.44px,
      20px 0%,
      calc(100% - 20px) 0%,
      calc(100% - 19.5px) 4.44px,
      calc(100% - 19px) 6.25px,
      calc(100% - 18px) 8.72px,
      calc(100% - 17px) 10.54px,
      calc(100% - 16px) 12px,
      calc(100% - 15px) 13.23px,
      calc(100% - 10px) 17.32px,
      calc(100% - 5px) 19.37px,
      100% 20px,
      100% 100%,
      0 100%
    );
  }
  &__footer {
    width: 100%;
    padding: 20px;
    background: $bg-opacity;
    display: flex;
  }
}
</style>
