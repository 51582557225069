<template>
  <div class="offer-item">
    <div
      :id="`popover-offer-item-${id}-${weaponId}`"
      class="item-wrapper"
      :class="{ hovering: isHover, selected: isSelected }"
      @mouseover="isHover = true"
      @mouseout="isHover = false"
      @click="handleClick"
    >
      <svg
        v-if="isSelected"
        class="tick"
        width="20"
        height="18"
        viewBox="0 0 30 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.17549 25.351L0.848506 14.697C0.300967 13.6019 0.163222 12.3471 0.460167 11.1593C0.862837 9.54865 2.97994 9.17057 3.91522 10.5423L9 18L26.2893 1.17795C26.9955 0.490867 28.0857 0.390453 28.9055 0.936981C29.7487 1.49914 29.9017 2.6763 29.2303 3.43537L9.46234 25.7817C8.52349 26.843 6.80918 26.6184 6.17549 25.351Z"
          fill="url(#paint0_linear_403_2438)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_403_2438"
            x1="15.25"
            y1="0"
            x2="15.25"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2EDE34" />
            <stop
              offset="1"
              stop-color="#39BA14"
            />
          </linearGradient>
        </defs>
      </svg>

      <!-- <div class="item__id text-secondary">
        ID {{ inventory.source_order_id }}
      </div> -->
      <!-- <div class="price text-white">
        ${{ inventory.price }}
      </div> -->

      <div class="item__image">
        <img
          :src="imageUrl"
          class="item__img embed-responsive"
        >
      </div>

      <div
        class="item__bg"
        :class="`background--${rarity}`"
      />

      <div class="item__info">
        <div class="item__name">
          <div class="name">
            {{ nameObject.excludeWear }}
          </div>
          <!-- <div class="type">
            {{ nameObject.type }}
          </div> -->
          <div class="wear">
            {{ nameObject.wear }}
          </div>
        </div>
      </div>
    </div>
    <b-popover
      :target="`popover-offer-item-${id}-${weaponId}`"
      triggers="hover"
      placement="top"
      :delay="delay"
    >
      {{ name }}
    </b-popover>
  </div>
</template>

<script>
import { nameFilter } from '@/utils'
import { ASSET_URL } from '@/config'

export default {
  name: 'InventoryItem',
  props: {
    inventory: Object,
    disabled: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    removeOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      isHover: false,
      oversize: false,
      delay: { show: 600, hide: 50 },
    }
  },
  computed: {
    nameObject() {
      return nameFilter(this.item?.name)
    },
    id() {
      return this.inventory?.id
    },
    weaponId() {
      return this.item?.id
    },
    name() {
      return this.item?.name
    },
    item() {
      return this.inventory?.item
    },
    imageUrl() {
      return ASSET_URL + this.inventory?.item.image_url
    },
    rarity() {
      return this.item?.rarity ? this.item?.rarity : 'lightblue'
    },
  },
  mounted() {
    // if (this.$refs.name && this.$refs.name.scrollWidth >= 240) {
    //   this.oversize = true
    // }
  },
  methods: {
    handleClick() {
      if (this.disabled) return
      const event = this.isSelected || this.removeOnly ? 'remove' : 'select'
      this.$emit(event, this.inventory)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.offer-item {
  height: 130px;
  padding: 5px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}

.action {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  button {
    flex: 0 33.333%;
  }
}

.price {
  font-size: 0.625em;
}

.status {
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: 27px;
}

.tick {
  position: absolute;
  right: 10px;
  top: 10px;
}

@each $rarity, $radio in $radios {
  .background--#{$rarity} {
    background: $radio;
  }
}

@each $rarity, $color in $rarities {
  .background--#{$rarity} {
    border-color: $color !important;
  }
}

.item {
  position: relative;
  cursor: pointer;
  user-select: none;

  &-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    background: #141a24;

    &.hovering {
      /* box-shadow: 0 0 10px 0px $primary; */
      .item__bg {
        background: $primary-radio;
        border-color: $primary !important;
      }
      .item__image {
        transform: scale(1.15);
      }
      .item__info {
        .name {
          color: $primary;
        }
        .oversize {
          color: $primary;
        }
      }
    }
    &.selected {
      .item__bg {
        background: $primary-radio;
        border-color: $primary !important;
      }
      .item__info {
        .name {
          color: $primary;
        }
        .oversize {
          color: $primary;
        }
      }
    }
  }

  &__id {
    position: absolute;
    right: 10px;
  }
  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 4px solid $secondary;
  }
  &__name {
    white-space: nowrap;

    .wear {
      height: 16px;
      font-size: 0.6rem;
      color: #666;
    }
    .type {
      color: #a5a5a5;
      font-size: 0.8rem;
    }
  }
  &__info {
    position: absolute;
    bottom: 5px;
    color: #fff;
    font-size: 0.625rem;
    padding: 0;
  }
  &__img {
    -webkit-user-drag: none;
    &:hover {
      animation: bounce 4s ease-in-out 1s infinite alternate;
    }
  }
  &__image {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px) translateX(1px);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
